<template>
  <div class="row">
    <!-- <div class="col-lg-3">
      <b-card no-body class="card-apply-job">
        <b-card-header
          class="d-flex justify-content-between align-items-center card_title"
        >
          <h4>Search Filters</h4>
        </b-card-header>
        <b-card-body>
          <div class="">
            <b-form class="auth-login-form mt-2" @submit.prevent="handleSearch">
              <b-form-group label="Name" label-for="Name">
                <b-form-input
                  v-model="form.searchName"
                  id="Name"
                  placeholder="Search by Name"
                />
              </b-form-group>
              <b-form-group label="Job Title" label-for="designation">
                <b-form-input
                  v-model="form.selectedJobTitle"
                  id="Name"
                  placeholder="Search by Job Title"
                />
              </b-form-group>
              <b-form-group>
                <div class="title__box">
                  <span>Company</span>
                </div>
                <div class="d-flex mb-1">
                  <b-form-checkbox
                    v-model="form.company"
                    value="present"
                    name="present"
                    class="mr-1"
                  >
                    Present
                  </b-form-checkbox>
                  <b-form-checkbox
                    v-model="form.company"
                    value="past"
                    name="past"
                  >
                    Past
                  </b-form-checkbox>
                </div>
                <b-form-input
                  v-model="form.selectedCompany"
                  id="Name"
                  placeholder="Search by Company"
                />
              </b-form-group>
              <b-form-group>
                <div class="title__box">
                  <span>Location</span>
                </div>
                <b-form-input
                  id="Location"
                  v-model="form.location"
                  placeholder="Search by Location"
                />
              </b-form-group>
              <b-form-group label="Institute" label-for="selectInstitute">
                <b-form-input
                  v-model="form.selectInstitute"
                  id="Name"
                  placeholder="Search by Institute"
                />
              </b-form-group>
              <b-form-group label="Industries" label-for="selectedIndustries">
                <v-select
                  v-model="form.selectedIndustries"
                  multiple
                  :options="industries"
                  :get-option-label="getSelected"
                  placeholder="Search by Industry"
                />
              </b-form-group>

              <b-form-group
                label="Year of Graduation"
                label-for="graduation-year"
              >
                <div class="d-flex align-items-center">
                  <span class="mr-1"> From</span>
                  <b-form-input
                    id="graduationStartDay"
                    v-model="form.graduationStartYear"
                    placeholder="Year"
                    size="sm"
                    @change="handleGraduationStartYear()"
                  />
                  <span class="mx-1">To</span>
                  <b-form-input
                    id="lastname"
                    v-model="form.graduationEndYear"
                    placeholder="Year"
                    size="sm"
                    @change="handleGraduationEndYear()"
                  />
                </div>
              </b-form-group>

              <b-form-group label="Function" label-for="function">
                <v-select
                  v-model="form.selectedFunctions"
                  multiple
                  label="title"
                  :options="functions"
                  :get-option-label="getSelected"
                  placeholder="Search by Function"
                />
              </b-form-group>
              <b-form-group label="Sub-Function" label-for="subFunction">
                <v-select
                  v-model="form.selectedSubFunctions"
                  multiple
                  label="title"
                  :options="subFunctions"
                  :get-option-label="getSelected"
                  placeholder="Search by Sub Function"
                />
              </b-form-group>
              <b-form-group label="Skill" label-for="skill">
                <v-select
                  v-model="form.selectedSkills"
                  multiple
                  label="name"
                  :options="skills"
                  :get-option-label="getSelected"
                  placeholder="Search by Skill"
                />
              </b-form-group>
              <b-button type="submit" variant="success" block>
                Search
              </b-button>
            </b-form>
          </div>
        </b-card-body>
      </b-card>
    </div> -->

    <div class="col-lg-12">
      <b-card no-body class="p-1">
        <b-row>
          <b-col
            lg="5"
            md="5"
            sm="4"
            class="pb-1 my-auto d-flex justify-content-start align-items-center"
          >
            <b-form-group class="d-flex my-auto">
              <label class="d-inline text-sm-left">Per page</label>
              <b-form-select
                id="perPageSelect"
                v-model="perPage"
                size="sm"
                :options="pageOptions"
                class="w-50"
              />
            </b-form-group>
          </b-col>

          <b-col
            lg="7"
            md="7"
            class="pb-1 d-flex justify-content-end align-items-center"
          >
            <b-form-group
              label-cols-sm="3"
              label-align-sm="right"
              label-size="sm"
              label-for="filterInput"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Type to Search"
                />
                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''">
                    Clear
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <b-button
              size="sm"
              class="ml-1 btn btn-success"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-modal.modal-login
              @click="routeToAddClient"
            >
              Add New
            </b-button>
          </b-col>

          <b-col cols="12">
            <b-table
              :items="items"
              :fields="fields"
              :per-page="perPage"
              :current-page="currentPage"
              responsive
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
            >
              <template #cell(show_details)="row">
                <b-form-checkbox
                  v-model="row.detailsShowing"
                  class="vs-checkbox-con"
                  @change="row.toggleDetails"
                >
                  <span class="vs-label">{{
                    row.detailsShowing ? "Hide" : "Show"
                  }}</span>
                </b-form-checkbox>
              </template>
              <template #row-details="row">
                <b-card class="mb-0">
                  <div class="card__box">
                    <div class="card__left">
                      <!-- <b-col md="8" class="pb-1">
                        <div class="row">
                          <div class="y-center col-lg-3 bor-1">
                            <h6>Email</h6>
                          </div>
                          <div class="col-lg-9">
                            <span>{{ row.item.email }}</span>
                          </div>
                        </div>
                      </b-col> -->
                      <b-col md="8" class="pb-1">
                        <div class="row">
                          <div class="y-center col-lg-3 bor-1">
                            <h6>Assignments</h6>
                          </div>
                          <div
                            class="col-lg-9"
                            v-if="row.item && row.item.assignment.length !== 0"
                          >
                            <div
                              v-for="(assignment, i) in row.item.assignment"
                              :key="i"
                            >
                              <b-link
                                class="alert-link"
                                @click="openAssignment(assignment)"
                              >
                                {{ assignment.title }}
                              </b-link>
                            </div>
                          </div>
                          <div class="col-lg-9" v-else>No Assignments Yet</div>
                        </div>
                      </b-col>
                      <b-col
                        md="8"
                        class="pb-1"
                        v-if="
                          row.item.client_users &&
                          row.item.client_users.length != 0
                        "
                      >
                        <div class="row">
                          <div class="y-center col-lg-3 bor-1">
                            <h6>Client Users</h6>
                          </div>
                          <div class="col-lg-9">
                            <div
                              v-for="(user, i) in row.item.client_users"
                              :key="i"
                            >
                              {{ user.email }}
                              <b-badge
                                v-if="user.role_id === 6"
                                variant="light-primary"
                                size="sm"
                              >
                                Super User
                              </b-badge>
                            </div>
                          </div>
                        </div>
                      </b-col>
                    </div>
                  </div>
                </b-card>
              </template>
              <template #cell(action)="data">
                <div class="text-nowrap">
                  <!-- Dropdown -->
                  <b-dropdown variant="link" toggle-class="p-0" no-caret left>
                    <template #button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="align-middle text-body"
                      />
                    </template>
                    <b-dropdown-item @click="routeToAddClientUser(data.item)">
                      <span class="align-middle ml-50">Add Client User</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="routeToAddClientAssignment(data.item)"
                    >
                      <span class="align-middle ml-50"
                        >Add Client Assignments</span
                      >
                    </b-dropdown-item>
                    <b-dropdown-item @click="viewClient(data.item)">
                      <span class="align-middle ml-50">View Client</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </template>
            </b-table>
          </b-col>
          <b-col cols="12">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="center"
              size="sm"
              class="my-0"
            />
          </b-col>
        </b-row>
      </b-card>
    </div>
    <b-modal
      v-model="modalShow"
      cancel-variant="outline-secondary"
      centered
      size="lg"
      hide-footer
      title="Add Client"
    >
      <validation-observer ref="addClientForm">
        <b-form @submit.prevent="handleAddClient">
          <b-row>
            <b-col cols="12" sm="6">
              <b-form-group>
                <label for="name">Name:</label>
                <validation-provider
                  #default="{ errors }"
                  name="Name"
                  rules="required"
                >
                  <b-form-input
                    id="name"
                    v-model="addForm.name"
                    type="text"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12" sm="6">
              <b-form-group>
                <label for="about">About:</label>
                <b-form-input
                  id="about"
                  v-model="addForm.about"
                  type="text"
                  placeholder="About"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" sm="6">
              <b-form-group>
                <label for="name">Client Name:</label>
                <validation-provider
                  #default="{ errors }"
                  name="Client Name"
                  rules="required"
                >
                  <b-form-input
                    id="name"
                    v-model="addForm.client_name"
                    type="text"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Client Name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12" sm="6">
              <b-form-group>
                <label for="email">Email:</label>
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required"
                >
                  <b-form-input
                    id="email"
                    v-model="addForm.email"
                    type="text"
                    placeholder="Email"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12" sm="6">
              <b-form-group>
                <label for="phone">Phone:</label>
                <validation-provider
                  #default="{ errors }"
                  name="Phone"
                  rules="required"
                >
                  <b-form-input
                    id="phone"
                    v-model="addForm.phone"
                    type="text"
                    placeholder="Phone"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12" sm="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Country"
                  rules="required"
                >
                  <label for="country">Country:</label>
                  <b-form-input
                    id="country"
                    v-model="addForm.country"
                    type="text"
                    placeholder="Country"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12" sm="6">
              <b-form-group>
                <label for="state">State:</label>
                <validation-provider
                  #default="{ errors }"
                  name="State"
                  rules="required"
                >
                  <b-form-input
                    id="state"
                    v-model="addForm.state"
                    type="text"
                    placeholder="State"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12" sm="6">
              <b-form-group>
                <label for="city">City:</label>
                <validation-provider
                  #default="{ errors }"
                  name="City"
                  rules="required"
                >
                  <b-form-input
                    id="city"
                    v-model="addForm.city"
                    type="text"
                    placeholder="City"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12" sm="6">
              <b-form-group>
                <label for="address1">Address :</label>
                <b-form-input
                  id="address1"
                  v-model="addForm.address1"
                  type="text"
                  placeholder="Address"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" sm="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Zip code"
                  rules="required"
                >
                  <label for="zipcode">Zip code :</label>
                  <b-form-input
                    id="zipcode"
                    v-model="addForm.zipcode"
                    type="text"
                    placeholder="Zip code"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12" sm="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Zip code"
                  rules="required"
                >
                  <label for="website">Website :</label>
                  <b-form-input
                    id="website"
                    v-model="addForm.website"
                    type="text"
                    placeholder="Website"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12" sm="6">
              <b-form-group>
                <label for="payment">Payment :</label>
                <b-form-input
                  id="payment"
                  v-model="addForm.payment"
                  type="text"
                  placeholder="Payment"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" sm="6">
              <b-form-group>
                <label for="payment_phone">UPI :</label>
                <b-form-input
                  id="paypayment_phonement"
                  v-model="addForm.payment_phone"
                  type="text"
                  placeholder="UPI"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-button
                class="btn btn-success float_right"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                type="submit"
                @click.prevent="handleAddClient"
              >
                Save
              </b-button>
              <b-button
                class="btn float_right mr-1"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                type="submit"
                @click="modalShow = !modalShow"
              >
                Close
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import axios from "@/libs/axios"
import store from "@/store/index"
import {
  BButton,
  BCard,
  BCol,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BPagination,
  BRow,
  BTable,
  BForm,
  BDropdown,
  BDropdownItem,
  BLink,
  BBadge,
} from "bootstrap-vue"
import Ripple from "vue-ripple-directive"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import { ValidationObserver, ValidationProvider } from "vee-validate"
import { required } from "@validations"
import errorResponse from "@/libs/axiosError"

export default {
  components: {
    BTable,
    BButton,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BForm,
    ValidationObserver,
    ValidationProvider,
    BDropdown,
    BDropdownItem,
    BLink,
    BBadge,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      modalShow: false,
      required,
      showExperience: 3,
      showQualification: 3,
      graduationStartYear: null,
      graduationEndYear: null,
      value2: null,
      graduation: [],
      dir: "ltr",
      trueValue: true,
      perPage: 10,
      pageOptions: [5, 10, 15],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterTwo: "",
      filterOn: [],
      selectInstitute: [],
      selectedCompany: [],
      selectedCompanyType: null,
      selectedLocType: null,
      selectedIndustries: [],
      industries: [],
      selectedFunctions: [],
      functions: [],
      selectedSubFunctions: [],
      subFunctions: [],
      selectedSkills: [],
      location: "",
      searchName: "",
      skills: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        "show_details",
        { key: "name", label: "Name", sortable: true },
        { key: "city", label: "Location", sortable: true },
        { key: "status", label: "Status", sortable: true },
        {
          key: "website",
          label: "website",
          sortable: true,
        },
        "action",
      ],
      items: [],
      status: [
        {
          1: "Current",
          2: "Professional",
          3: "Rejected",
          4: "Resigned",
          5: "Applied",
        },
        {
          1: "light-primary",
          2: "light-success",
          3: "light-danger",
          4: "light-warning",
          5: "light-info",
        },
      ],
      searchFilter: "",
      form: {
        searchName: "",
        selectedJobTitle: "",
        company: "",
        selectedCompany: "",
        location: "",
        selectInstitute: "",
        selectedIndustries: "",
        graduationStartYear: "",
        graduationEndYear: "",
        selectedFunctions: "",
        subFunctions: "",
        selectedSkills: "",
      },
      addForm: {
        name: "",
        about: "",
        email: "",
        phone: "",
        country: "",
        state: "",
        city: "",
        address1: "",
        zipcode: "",
        website: "",
        payment: "",
        payment_phone: "",
      },
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }))
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = "rtl"
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = "ltr"
      return this.dir
    },
  },
  watch: {},
  mounted() {
    this.getClients(this.searchFilter)
  },
  created() {},
  updated() {
    // this.row.toggleDetails = true
  },

  methods: {
    getSelected(option) {
      return (option && option.name) || ""
    },
    handleSearch() {
      let filter = "?"
      if (this.form.searchName) {
        filter += `filter[name]=${this.form.searchName}`
      }
      if (this.form.selectedJobTitle) {
        filter += `filter[job_title]=${this.form.selectedJobTitle}`
      }
      if (this.form.selectedCompany) {
        filter += `filter[industry]=${this.form.selectedCompany}`
      }
      if (this.form.selectInstitute) {
        filter += `filter[education_institute]=${this.form.selectInstitute}`
      }
      if (this.form.graduationStartYear) {
        filter += `filter[education_from]=${this.form.graduationStartYear}`
      }
      if (this.form.graduationEndYear) {
        filter += `filter[education_to]=${this.form.graduationEndYear}`
      }
      if (this.form.location) {
        filter += `filter[country]=${this.form.location}`
      }
      this.getClients(filter)
    },
    handleGraduationStartYear() {
      const startYear = Math.floor(this.graduationStartYear)
      this.graduation[0] = startYear
    },
    handleGraduationEndYear() {
      this.value2 = Math.floor(this.graduationEndYear)
      const endYear = Math.floor(this.graduationEndYear)
      this.graduation[1] = endYear
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit("bv::show::modal", this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ""
      this.infoModal.content = ""
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    async getClients(filters) {
      axios
        .get(`/clients${filters}`)
        .then((res) => {
          this.items = res.data
          this.totalRows = this.items.length

          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Candidate List`,
              icon: "CoffeeIcon",
              variant: "success",
              text: `You have successfully fetched Candidate List!`,
            },
          })
        })
        .catch((error) => {
          errorResponse(error, this.$router)
        })
    },
    async handleAddClient() {
      this.$refs.addClientForm.validate().then((success) => {
        if (success) {
          axios
            .post("/add_client", this.addForm)
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Candidate Addition`,
                  icon: "CoffeeIcon",
                  variant: "success",
                  text: `You have successfully Created Candidate List!`,
                },
              })
              this.getClients(this.searchFilter)
              this.modalShow = false
            })
            .catch((error) => {
              console.log(error)
              this.modalShow = false
              errorResponse(error, this.$router)
            })
        }
      })
    },
    routeToAddClient() {
      this.$router.push({
        path: "/add-client",
      })
    },
    routeToAddClientUser(data) {
      this.$router.push({
        path: `/add-client-user/${data.id}`,
      })
    },
    routeToAddClientAssignment(data) {
      this.$router.push({
        path: `/add-client-assignment/${data.id}`,
      })
    },
    openAssignment(data) {
      this.$router.push({
        path: `/details-admin-engagement/${data.id}`,
      })
    },
    viewClient(data) {
      this.$router.push({
        path: `/client-details/${data.id}`,
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-slider.scss";
@import "@core/scss/vue/libs/vue-select.scss";
.bor-1 {
  border-right: 1px solid lightgray;
}

.in__line {
  display: flex;
  flex-direction: row;
}

.y-center {
  display: flex;
  flex-direction: column;

  justify-content: center;
}

.title__box {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.card__box {
  display: flex;
  justify-content: space-between;
}

.card__left {
  flex: 1;
}
.loc {
  font-size: 11px;
  padding-right: 5px;
}

.font-11 {
  font-size: 11px;
}

.bold {
  font-family: bold;
}
.inline {
  display: inline !important;
}

.name {
  margin-bottom: 0px;
}
.timeline-item {
  padding-bottom: 5px !important;
}

.card-body {
  // padding-bottom: 0px !important;
}
.experience_btn {
  cursor: pointer;
}
.location {
  display: inline !important;
}
.icon {
  margin-top: 5px;
}
.executive {
  margin-right: 5px;
  font-size: 12px;
}
.float_right {
  float: right;
}
</style>

Row select support Selection mode: multi ID AVAT
